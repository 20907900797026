import AggridTable from "./AggridTable";
import ButtonColumn from "./ButtonColumn";
import ProductMarginItem from "./ProductMarginItem";
import React from "react";
import { Table } from "react-bootstrap";
import { updateMarginGotovinskoValue } from "../../../actions/product";
import { useDispatch } from "react-redux";

const ProductMarginTable = ({ data }) => {
  const dispatch = useDispatch();

  const handleInputChange = (vrednost, kategorija, tip) => {
    const dataValue = {
      vrednost: vrednost,
      kategorija: kategorija,
      tip: tip,
    };

    dispatch(updateMarginGotovinskoValue(dataValue));
  };

  const handleSubmitModal = () => {
    console.log("Brisanje kategorije");
  };

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/category-edit/` + params.data.id}
      deleteItemId={params.data.id}
      deleteItemTitle={params.data.name}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "id", filter: true, width: 100 },
    {
      headerName: "Kategorija",
      field: "name",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 250,
      enableRowGroup: true,
    },
    {
      headerName: "Marža %",
      field: "marza",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Gotovinsko %",
      field: "gotovinsko",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Pripadnost kategoriji",
      field: "parentName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      hide: true,
    },
    {
      headerName: "",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 90,
      sortable: false,
      filter: false,
    },
  ];

  return (
    <AggridTable
      rowData={data}
      columnDefs={columnDefs}
      showButtons={false}
      paginationPageSize={100}
    />
  );
};

export default ProductMarginTable;
