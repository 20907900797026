import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import {
  fetchCategoriesPagesList,
  fetchCategoriesPrentList,
  fetchCreateNewCategories,
  fetchEditCategories,
} from "../actions/categories";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import CheckIcon from "@mui/icons-material/Check";
import { Fragment } from "react";
import InputField from "../components/Admin/Reusable/InputField";
import { basicFormValidationHandler } from "../utils";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useState } from "react";

const Categories = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataPagesDetail, dataParentCategoriesList } = useSelector(
    (state) => state.categoriesReducer
  );

  const resetState = useCallback(() => {
    return {
      name: "",
      status: 1,
      parentId: "",
      marza: 0,
      gotovinsko: 0,
    };
  }, []);

  const initialState = {
    name: "",
    status: 1,
    parentId: "",
    marza: 0,
    gotovinsko: 0,
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [flatCategoryList, setFlatCategoryList] = useState([]);

  useEffect(() => {
    if (id) {
      //pozvati detalje proizvoda
      dispatch(fetchCategoriesPagesList(id));
    } else {
      setData(resetState());
    }
  }, [id, dispatch, resetState]);

  //pozvivanje kategorija
  useEffect(() => {
    if (!data.parentId) {
      dispatch(fetchCategoriesPrentList(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      setData(dataPagesDetail[0]);
      setSelectedOptions(dataPagesDetail[0]["privileges"]);
    }
  }, [dataPagesDetail]);

  const categoriesValidationHandler = () => {
    const { name } = data;
    const errorsObject = basicFormValidationHandler({
      name,
    });
    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (categoriesValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("status", data.status);
    formData.append("parentId", data.parentId);
    formData.append("marza", data.marza);
    formData.append("gotovinsko", data.gotovinsko);

    if (id) {
      formData.append("id", data.id);
      dispatch(fetchEditCategories(formData, navigate));
    } else {
      dispatch(fetchCreateNewCategories(formData, navigate));
    }
  };

  const handleCheckboxChange = (event, option) => {
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  useEffect(() => {
    const flattenCategories = (categories, level = 0) => {
      let flatList = [];

      categories.forEach((category) => {
        if (category.id === data.id) return;

        flatList.push({ ...category, level });
        if (category.children) {
          flatList = flatList.concat(
            flattenCategories(category.children, level + 1)
          );
        }
      });

      return flatList;
    };

    setFlatCategoryList(flattenCategories(dataParentCategoriesList));
  }, [dataParentCategoriesList]);

  return (
    <Fragment>
      <Breadcrumb title="Kategorije proizvoda" type="category-add" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {/* Kreiranje novog pitanja */}
                {id ? `Izmena kategorije` : "Kreiranje nove kategorije"}
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-6">
                    <InputField
                      required
                      label="Naziv kategorije"
                      name="name"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.name}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Pripada kategoriji
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.parentId}
                        label="Pripada kategoriji"
                        name="parentId"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="0">Glavna kategorija</MenuItem>
                        {flatCategoryList.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            style={{ marginLeft: `${item.level * 20}px` }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <InputField
                      required
                      label="Marža"
                      name="marza"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.marza}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <InputField
                      required
                      label="Procenat za gotovinsko plaćanje"
                      name="gotovinsko"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.gotovinsko}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Status kategorije
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.status}
                        label="Status kategorije"
                        name="status"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Aktivan</MenuItem>
                        <MenuItem value="0">Neaktivan</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Categories;
