import { useDispatch, useSelector } from "react-redux";

import NavItem from "./NavItem";
import React from "react";
import { fetchSidebarMenu } from "../../../actions/security";
import { useEffect } from "react";

const LeftSidebar = ({ menuActive }) => {
  const dispatch = useDispatch();

  const { setMenuItemsList, loggedUser } = useSelector(
    (state) => state.loggedUserReducer
  );

  const action = loggedUser?.loggedUser?.roles[0];
  useEffect(() => {
    dispatch(fetchSidebarMenu());
  }, [dispatch]);

  const menuItemsList = setMenuItemsList?.filter((item) =>
    action.includes(item.acModule)
  );

  return (
    <div className={`sidebar-main-content ${menuActive && "active"}`}>
      <div className="navbar-brand-box">
        <div href="#0" className="logo logo-light">
          <span className="logo-lg">
            <img
              src="/images/logo-developers-white.png"
              alt="Developers.rs"
              className="img-fluid"
            />
          </span>
        </div>
      </div>
      <ul className="navbar-nav" id="navbar-nav">
        {menuItemsList?.map((item, key) => (
          <NavItem item={item} key={key} />
        ))}
      </ul>
    </div>
  );
};

export default LeftSidebar;
