import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { Fragment } from "react";
import LoadingIndicator from "../components/Admin/Reusable/LoadingIndicator";
import ProductMarginTable from "../components/Admin/Reusable/ProductMarginTable";
import { fetchCategoryGroupList } from "../actions/product";
import { useParams } from "react-router-dom";

const ProductMargin = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { dataCategoryGroup, loading } = useSelector(
    (state) => state.productReducer
  );

  useEffect(() => {
    dispatch(fetchCategoryGroupList());
  }, [id, dispatch]);

  return (
    <Fragment>
      <Breadcrumb title="Kategorije" type="category-list" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">
                  {id
                    ? `Izmena grupe marze proizvoda`
                    : "Izmena grupe marze proizvoda"}
                </h1>
              </div> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    {!!dataCategoryGroup && (
                      <ProductMarginTable data={dataCategoryGroup} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingIndicator loading={loading} />
    </Fragment>
  );
};

export default ProductMargin;
