import {
  fetchDeleteProduct,
  fetchLoadProducts,
  fetchProductPagesList,
} from "../actions/product";
import { useDispatch, useSelector } from "react-redux";

import AggridTable from "../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { Button } from "@mui/material";
import ButtonColumn from "../components/Admin/Reusable/ButtonColumn";
import { Fragment } from "react";
import LoadingIndicator from "../components/Admin/Reusable/LoadingIndicator";
import React from "react";
import { statusRenderStatus } from "../utils/cellRenderTable";
import { useEffect } from "react";

const ProductList = () => {
  const dispatch = useDispatch();

  const { dataPagesList, loading } = useSelector(
    (state) => state.productReducer
  );

  useEffect(() => {
    dispatch(fetchProductPagesList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteProduct(pageId));
  };

  const imageRenderer = (params) =>
    params.data.slika && (
      <img
        src={params.data.slika}
        className="img-fluid image-preview-block-table"
        alt="UP IT"
      />
    );

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/product-edit/` + params.data.id}
      deleteItemId={params.data.anId}
      deleteItemTitle={params.data.acName}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    // { headerName: "ID", field: "id", filter: true, width: 100 },
    {
      headerName: "Slika",
      field: "slika",
      width: 100,
      cellRenderer: imageRenderer,
    },
    {
      headerName: "Šifra",
      field: "sifra",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Grupa proizvoda",
      field: "grupa",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Proizvođač",
      field: "proizvodjac",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Naziv proizvoda",
      field: "naziv",
      filter: "agTextColumnFilter",
      width: 350,
    },
    {
      headerName: "Kol.",
      field: "kolicina",
      filter: "agTextColumnFilter",
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        // Funkcija koja se koristi za sortiranje numeričkih vrednosti
        return parseFloat(valueA) - parseFloat(valueB);
      },
      width: 100,
    },
    {
      headerName: "Jed.mere",
      field: "jedinica_mere",
      filter: "agTextColumnFilter",
      width: 120,
    },
    {
      headerName: "B2B Cena",
      field: "b2bcena",
      width: 150,
      filter: "agTextColumnFilter",
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        // Funkcija koja se koristi za sortiranje numeričkih vrednosti
        return parseFloat(valueA) - parseFloat(valueB);
      },
    },
    {
      headerName: "Cena bez pdv",
      field: "cena_bez_pdv",
      filter: "agTextColumnFilter",
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        // Funkcija koja se koristi za sortiranje numeričkih vrednosti
        return parseFloat(valueA) - parseFloat(valueB);
      },
      width: 140,
    },
    {
      headerName: "Cena sa pdv",
      field: "cena",
      filter: "agTextColumnFilter",
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        // Funkcija koja se koristi za sortiranje numeričkih vrednosti
        return parseFloat(valueA) - parseFloat(valueB);
      },
      width: 140,
    },
    {
      headerName: "Nova cena",
      field: "cena_sa_popustom",
      filter: "agTextColumnFilter",
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        // Funkcija koja se koristi za sortiranje numeričkih vrednosti
        return parseFloat(valueA) - parseFloat(valueB);
      },
      width: 180,
    },
    {
      headerName: "Cena za gotovinsko sa pdv",
      field: "cena_gotovinsko",
      filter: "agTextColumnFilter",
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        // Funkcija koja se koristi za sortiranje numeričkih vrednosti
        return parseFloat(valueA) - parseFloat(valueB);
      },
      width: 240,
    },
    {
      headerName: "Status",
      field: "status_za_aggrid",
      filter: "agTextColumnFilter",
      cellRenderer: statusRenderStatus,
      width: 150,
      filterParams: {
        defaultOption: "startsWith",
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Nad grupa",
      field: "nadgrupa",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "WEB Cena",
      field: "web_cena",
      filter: "agTextColumnFilter",
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        // Funkcija koja se koristi za sortiranje numeričkih vrednosti
        return parseFloat(valueA) - parseFloat(valueB);
      },
      width: 150,
    },
    {
      headerName: "MP Cena",
      field: "mpcena",
      filter: "agTextColumnFilter",
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        // Funkcija koja se koristi za sortiranje numeričkih vrednosti
        return parseFloat(valueA) - parseFloat(valueB);
      },
      width: 150,
    },
    {
      headerName: "Barcode",
      field: "barcod",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 120,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled proizvoda" type="product-lista" />

      <AggridTable
        rowData={dataPagesList}
        columnDefs={columnDefs}
        paginationPageSize={100}
      />
      <LoadingIndicator loading={loading} />
    </Fragment>
  );
};

export default ProductList;
