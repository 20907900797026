const breadcrumbsItems = (type) => {
  switch (type) {
    case "seo-stranice":
      return (
        <>
          <li className="breadcrumb-item">Seo stranice</li>
          <li className="breadcrumb-item active">Pregled stranica</li>
        </>
      );
    case "korisnici-forma":
      return (
        <>
          <li className="breadcrumb-item">Korisnici</li>
          <li className="breadcrumb-item active">Dodavanje novog korisnika</li>
        </>
      );
    case "seo-form-edit":
      return (
        <>
          <li className="breadcrumb-item">Seo stranice</li>
          <li className="breadcrumb-item">Pregled stranice</li>
          <li className="breadcrumb-item active">Izmena seo stranice</li>
        </>
      );
    case "qa-forma":
      return (
        <>
          <li className="breadcrumb-item">Česta pitanja</li>
          <li className="breadcrumb-item active">Kreiranje novog pitanja</li>
        </>
      );
    case "qa-sort":
      return (
        <>
          <li className="breadcrumb-item">Česta pitanja sortiranje</li>
          <li className="breadcrumb-item active">Sortiranje pitanja</li>
        </>
      );
    case "qa-lista":
      return (
        <>
          <li className="breadcrumb-item">Česta pitanja</li>
          <li className="breadcrumb-item active">Pregled pitanja</li>
        </>
      );
    case "document-forma":
      return (
        <>
          <li className="breadcrumb-item">Dokumenta</li>
          <li className="breadcrumb-item active">Kreiranje novog dokumenta</li>
        </>
      );
    case "document-lista":
      return (
        <>
          <li className="breadcrumb-item">Dokumenta</li>
          <li className="breadcrumb-item active">Pregled dokumenata</li>
        </>
      );
    case "client-forma":
      return (
        <>
          <li className="breadcrumb-item">Klijent</li>
          <li className="breadcrumb-item active">Kreiranje novog klijenta</li>
        </>
      );
    case "client-lista":
      return (
        <>
          <li className="breadcrumb-item">Klijent</li>
          <li className="breadcrumb-item active">Pregled klijenata</li>
        </>
      );
    case "blog-forma":
      return (
        <>
          <li className="breadcrumb-item">Blog</li>
          <li className="breadcrumb-item active">Kreiranje novog bloga</li>
        </>
      );
    case "blog-lista":
      return (
        <>
          <li className="breadcrumb-item">Blog</li>
          <li className="breadcrumb-item active">Pregled blogova</li>
        </>
      );
    case "blog-detail":
      return (
        <>
          <li className="breadcrumb-item">Blog detalji</li>
          <li className="breadcrumb-item active">
            Kreiranje novog bloga detalja
          </li>
        </>
      );
    case "caseStudy-forma":
      return (
        <>
          <li className="breadcrumb-item">Case Study</li>
          <li className="breadcrumb-item active">Kreiranje novog case study</li>
        </>
      );
    case "caseStudy-lista":
      return (
        <>
          <li className="breadcrumb-item">Case Study</li>
          <li className="breadcrumb-item active">Pregled case study</li>
        </>
      );
    case "contactForm-lista":
      return (
        <>
          <li className="breadcrumb-item">Kontakt forma</li>
          <li className="breadcrumb-item active">Pregled kontakata</li>
        </>
      );
    case "role-forma":
      return (
        <>
          <li className="breadcrumb-item">Role</li>
          <li className="breadcrumb-item active">Kreiranje nove role</li>
        </>
      );
    case "role-lista":
      return (
        <>
          <li className="breadcrumb-item">Role</li>
          <li className="breadcrumb-item active">Pregled rola</li>
        </>
      );
    case "user-forma":
      return (
        <>
          <li className="breadcrumb-item">Korisnici</li>
          <li className="breadcrumb-item active">Kreiranje novog korisnika</li>
        </>
      );
    case "user-lista":
      return (
        <>
          <li className="breadcrumb-item">Korisnici</li>
          <li className="breadcrumb-item active">Pregled korisnika</li>
        </>
      );
    case "testimonials-forma":
      return (
        <>
          <li className="breadcrumb-item">Rekli su o nama</li>
          <li className="breadcrumb-item active">Kreiraj novu stavku</li>
        </>
      );
    case "testimonials-lista":
      return (
        <>
          <li className="breadcrumb-item">Rekli su o nama</li>
          <li className="breadcrumb-item active">Pregled stavki</li>
        </>
      );
    case "team-forma":
      return (
        <>
          <li className="breadcrumb-item">Tim</li>
          <li className="breadcrumb-item active">Kreiranje novog člana</li>
        </>
      );
    case "team-lista":
      return (
        <>
          <li className="breadcrumb-item">Tim</li>
          <li className="breadcrumb-item active">Pregled tima</li>
        </>
      );
    case "product-forma":
      return (
        <>
          <li className="breadcrumb-item">Proizvod</li>
          <li className="breadcrumb-item active">Kreiranje novog proizvoda</li>
        </>
      );
    case "product-lista":
      return (
        <>
          <li className="breadcrumb-item">Proizvod</li>
          <li className="breadcrumb-item active">Pregled proizvoda</li>
        </>
      );
    case "slider-forma":
      return (
        <>
          <li className="breadcrumb-item">Slider</li>
          <li className="breadcrumb-item active">Kreiranje novog slidera</li>
        </>
      );
    case "slider-lista":
      return (
        <>
          <li className="breadcrumb-item">Slider</li>
          <li className="breadcrumb-item active">Pregled slidera</li>
        </>
      );
    case "banner-forma":
      return (
        <>
          <li className="breadcrumb-item">Baneri</li>
          <li className="breadcrumb-item active">Kreiranje novog banera</li>
        </>
      );
    case "banner-lista":
      return (
        <>
          <li className="breadcrumb-item">Baneri</li>
          <li className="breadcrumb-item active">Pregled banera</li>
        </>
      );
    case "product-sync":
      return (
        <>
          <li className="breadcrumb-item">Proizvod</li>
          <li className="breadcrumb-item active">
            Pregled azuriranja podataka
          </li>
        </>
      );
    case "newsletter-lista":
      return (
        <>
          <li className="breadcrumb-item">Newsletter</li>
          <li className="breadcrumb-item active">Pregled newsletter prijava</li>
        </>
      );
    case "category-list":
      return (
        <>
          <li className="breadcrumb-item">Kategorije proizvoda</li>
          <li className="breadcrumb-item active">Pregled kategorija</li>
        </>
      );
    case "category-add":
      return (
        <>
          <li className="breadcrumb-item">Kategorije proizvoda</li>
          <li className="breadcrumb-item active">Kreiranje kategorije</li>
        </>
      );
    default:
      return "";
  }
};

export default breadcrumbsItems;
