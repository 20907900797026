import {
  createNewCategoriesPath,
  editCategoriesPath,
  getListCategoriesParentPath,
  getListCategoriesPath,
  removeCategoriesPath,
} from "../constants/apiRoutes";

import apiService from "../utils/configAxios";
import { categoriesActions } from "../reducers/Admin/categoriesActions";
import { toast } from "react-toastify";

export const fetchCreateNewCategories = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewCategoriesPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/categories-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteCategories = (pageId) => {
  return (dispatch) => {
    dispatch(categoriesActions.actionStart());
    return apiService
      .delete(removeCategoriesPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListCategoriesPath(0))
          .then((response) => {
            dispatch(categoriesActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditCategories = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editCategoriesPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/categories-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCategoriesPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(categoriesActions.actionStart());
    return apiService
      .get(getListCategoriesPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(categoriesActions.fetchAllPagesList(response.data));
        else dispatch(categoriesActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCategoriesPrentList = () => {
  return (dispatch) => {
    dispatch(categoriesActions.actionStart());
    return apiService
      .get(getListCategoriesParentPath())
      .then((response) => {
        dispatch(
          categoriesActions.fetchAllPagesParentCategoriesList(response.data)
        );
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
