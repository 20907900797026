import React, { useState, Fragment, useEffect, useCallback } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";
import {
  createNewQa,
  fetchQaPagesList,
  editQaForm,
  fetchQaDetailData,
} from "../actions/qa";
import { useParams, useNavigate } from "react-router";
import { basicFormValidationHandler } from "../utils";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import InputField from "../components/Admin/Reusable/InputField";

const Qa = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataPagesDetail } = useSelector((state) => state.qaReducer);

  const initialState = {
    anStatus: "1",
    anCategory: "1",
    acTitle: "",
    acDescription: "",
    anLanguage: 1,
    acImage: null,
  };

  const resetState = useCallback(() => {
    return {
      anStatus: "1",
      anCategory: "1",
      acTitle: "",
      acDescription: "",
      anLanguage: 1,
      acImage: null,
    };
  }, []);

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      console.log(id, "id");
      dispatch(fetchQaDetailData(id));
    } else {
      setData(resetState());
    }
  }, [id, dispatch, resetState]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      console.log(dataPagesDetail, "dataPagesDetail");
      setData(dataPagesDetail);
    }
  }, [dataPagesDetail]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const clientValidationHandler = () => {
    const { acTitle } = data;
    const errorsObject = basicFormValidationHandler({
      acTitle,
    });
    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (clientValidationHandler()) {
      return;
    }

    if (id) {
      dispatch(editQaForm(data, navigate));
    } else {
      dispatch(createNewQa(data, navigate));
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Česta pitanja" type="qa-forma" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {/* Kreiranje novog pitanja */}
                {id
                  ? `Izmena pitanja ${data.acTitle}`
                  : "Kreiranje novog pitanja"}
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-5">
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Jezik
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anLanguage}
                        label="Jezik"
                        name="anLanguage"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Srpski</MenuItem>
                        <MenuItem value="2">Engleski</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Kategorija
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anCategory}
                        label="Kategorija"
                        name="anCategory"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Cyber security pitanja</MenuItem>
                        <MenuItem value="2">SOC</MenuItem>
                      </Select>
                    </FormControl>

                    <InputField
                      required
                      label="Pitanje"
                      multiline
                      name="acTitle"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acTitle}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anStatus}
                        label="Status"
                        name="anStatus"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Aktivan</MenuItem>
                        <MenuItem value="0">Neaktivan</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-7">
                    <InputLabel id="demo-simple-select-label" className="mb-2">
                      Odgovor:
                    </InputLabel>
                    <CKEditor
                      name="acDescription"
                      editor={Editor}
                      data={data.acDescription}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setData((prevUser) => ({
                          ...prevUser,
                          acDescription: data,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj novo pitanje
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Qa;
