import {
  createNewProductPath,
  createNewProductsSale,
  editProductPath,
  getCategoryGroupProduct,
  getFetchGrupe,
  getFetchKategorije,
  getFetchNadgrupe,
  getListProductCheckPath,
  getListProductPath,
  getLoadProductsCheckRefresh,
  getLoadProductsRefresh,
  getSyncListItems,
  getUpdateProductsPrice,
  removeProductPath,
  updateMarginGotovinskoFunction,
} from "../constants/apiRoutes";

import apiService from "../utils/configAxios";
import { productActions } from "../reducers/Admin/productActions";
import { toast } from "react-toastify";

export const fetchCreateNewProduct = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewProductPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/product-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteProduct = (pageId) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .delete(removeProductPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListProductPath(0))
          .then((response) => {
            dispatch(productActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditProduct = (dataValues, pageId) => {
  return (dispatch) => {
    return apiService
      .post(editProductPath(), dataValues)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListProductPath(pageId))
          .then((response) => {
            if (pageId === 0)
              dispatch(productActions.fetchAllPagesList(response.data));
            else dispatch(productActions.fetchAllPagesDetail(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchProductPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getListProductPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(productActions.fetchAllPagesList(response.data));
        else {
          dispatch(productActions.fetchAllPagesDetail(response.data));
          dispatch(fetchGetKategorije());
        }
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchGetKategorije = () => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getFetchKategorije())
      .then((response) => {
        dispatch(productActions.fetchAllKategorije(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchGetNadgrupe = (id) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getFetchNadgrupe(id))
      .then((response) => {
        dispatch(productActions.fetchAllNadgrupe(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchGetNadgrupeClear = (id) => {
  return (dispatch) => {
    dispatch(productActions.fetchClearNadgrupeList());
  };
};

export const fetchGetGrupe = (id) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getFetchGrupe(id))
      .then((response) => {
        dispatch(productActions.fetchAllGrupe(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchGetGrupeClear = (id) => {
  return (dispatch) => {
    dispatch(productActions.fetchClearGrupeList());
  };
};

export const fetchProductCheckPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getListProductCheckPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(productActions.fetchAllPagesCheckList(response.data));
        else dispatch(productActions.fetchAllPagesCheckDetail(response.data));
      })
      .catch((err) => {
        dispatch(productActions.clearError());
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchLoadProducts = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getLoadProductsRefresh())
      .then((response) => {
        return apiService
          .get(getUpdateProductsPrice())
          .then((response) => {
            dispatch(productActions.clearError());
            return apiService
              .get(getListProductPath(pageId))
              .then((response) => {
                toast.success("Podaci su uspešno osveženi", {
                  theme: "colored",
                });
                if (pageId === 0)
                  dispatch(productActions.fetchAllPagesList(response.data));
                else
                  dispatch(productActions.fetchAllPagesDetail(response.data));
              })
              .catch((err) => {
                dispatch(productActions.clearError());
                let errorMessage = err.response
                  ? err.response.data.message
                  : err;
                if (!err.response) {
                  toast.error(
                    "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                    { theme: "colored" }
                  );
                }
                toast.error(errorMessage, { theme: "colored" });
              });
          })
          .catch((err) => {
            dispatch(productActions.clearError());
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        dispatch(productActions.clearError());
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchLoadProductsCheck = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getLoadProductsCheckRefresh())
      .then((response) => {
        return apiService
          .get(getListProductCheckPath(pageId))
          .then((response) => {
            if (pageId === 0)
              dispatch(productActions.fetchAllPagesCheckList(response.data));
            else
              dispatch(productActions.fetchAllPagesCheckDetail(response.data));
          })
          .catch((err) => {
            dispatch(productActions.clearError());
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCategoryGroupList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getCategoryGroupProduct())
      .then((response) => {
        dispatch(productActions.fetchAllCategoryGroupList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchSyncList = () => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getSyncListItems())
      .then((response) => {
        dispatch(productActions.fetchAllSyncList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCreateCategoryGroupMargin = (dataValues) => {
  return (dispatch) => {
    return apiService
      .post(createNewProductsSale(), dataValues)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const updateMarginGotovinskoValue = (dataValues) => {
  return (dispatch) => {
    return apiService
      .post(updateMarginGotovinskoFunction(), dataValues)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
