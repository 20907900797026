import {
  fetchCategoriesPagesList,
  fetchDeleteCategories,
} from "../actions/categories";
import { useDispatch, useSelector } from "react-redux";

import AggridTable from "../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import ButtonColumn from "../components/Admin/Reusable/ButtonColumn";
import { Fragment } from "react";
import React from "react";
import { statusRenderStatusNew } from "../utils/cellRenderTable";
import { useEffect } from "react";

const CategoriesList = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.categoriesReducer);

  useEffect(() => {
    dispatch(fetchCategoriesPagesList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteCategories(pageId));
  };

  const customCellRenderer = (params) => {
    if (params.data) {
      console.log(params.data);
      return (
        <ButtonColumn
          editLink={`/adminManager/categories-edit/` + params.data.id}
          deleteItemId={params.data.id}
          deleteItemTitle={params.data.name}
          value={params}
          submitActionModal={handleSubmitModal}
        />
      );
    }
  };

  //settings za tabelu
  const columnDefs = [
    // { headerName: "ID", field: "id", filter: true, width: 100 },
    {
      headerName: "Nadgrupa",
      field: "parentName",
      filter: "agSetColumnFilter",
      width: 200,
      enableRowGroup: true,
    },
    {
      headerName: "Kategorija",
      field: "name",
      filter: "agTextColumnFilter",
      flex: 1,
      enableRowGroup: true,
    },
    {
      headerName: "Marža %",
      field: "marza",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      width: 150,
    },
    {
      headerName: "Gotovinsko %",
      field: "gotovinsko",
      filter: "agTextColumnFilter",
      width: 150,
      enableRowGroup: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      cellRenderer: statusRenderStatusNew,
      width: 150,
      filterParams: {
        defaultOption: "startsWith",
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 90,
      sortable: false,
      filter: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled kategorija" type="category-list" />
      <AggridTable
        rowData={dataPagesList}
        columnDefs={columnDefs}
        shouldHideRefreshButton={false}
      />
    </Fragment>
  );
};

export default CategoriesList;
