import React, { useState } from "react";

import { TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { login } from "../actions/security";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeHandler = (event) => {
    changeState(event.target.name, event.target.value);
  };

  const changeState = (propName, value) => {
    setState((prevState) => ({ ...prevState, [propName]: value }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(login(state, navigate));
  };

  return (
    <div className="auth-page-wrapper pt-5">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay"></div>

        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>
      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <a href="index.html" className="d-inline-block auth-logo">
                    <img
                      src="/images/logo-developers-white.png"
                      alt="Developers.rs"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <form onSubmit={handleLogin}>
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary title-login-main-welcome">
                        Dobrodošli !
                      </h5>
                      <p className="text-muted">
                        Prijavite se da biste nastavili.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <div className="mb-3">
                        <TextField
                          label="Korisničko ime"
                          name="username"
                          value={state.username}
                          onChange={(e) => onChangeHandler(e)}
                          required
                          fullWidth
                          className="mb-4"
                        />
                        {/* <input
                          type="text"
                          name="username"
                          className="form-control"
                          placeholder="Unesite korisničko ime"
                          value={state.username}
                          onChange={(e) => onChangeHandler(e)}
                        /> */}
                      </div>

                      <div className="mb-3">
                        {/* <div className="float-end">
                                                        <a href="auth-pass-reset-basic.html" className="text-muted">Forgot password?</a>
                                                    </div> */}
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <TextField
                            label="Lozinka"
                            name="password"
                            value={state.password}
                            onChange={(e) => onChangeHandler(e)}
                            required
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            className="mb-4"
                          />
                          {showPassword ? (
                            <VisibilityOffIcon
                              className="icon-show-hide-password-input"
                              onClick={() => setShowPassword(!showPassword)}
                            />
                          ) : (
                            <VisibilityIcon
                              className="icon-show-hide-password-input"
                              onClick={() => setShowPassword(!showPassword)}
                            />
                          )}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                            fdprocessedid="zb3phh"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          fdprocessedid="3qhhk"
                        >
                          Prijava
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mb-0 text-muted">
                  ©<script>document.write(new Date().getFullYear())</script>
                  2023 Developers.rs
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Login;
