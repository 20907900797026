import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataPagesList: [],
  dataPagesDetail: [],
  dataPagesCheckList: [],
  dataPagesCheckDetail: [],
  dataCategoryGroup: [],
  dataCategoryKategorije: [],
  dataCategoryNadgrupe: [],
  dataCategoryGrupe: [],
  dataSyncList: [],
  loading: false,
  error: undefined,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
      state.loading = false;
    },
    actionStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchAllPagesList(state, action) {
      state.dataPagesList = action.payload;
      state.dataPagesDetail = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesDetail(state, action) {
      state.dataPagesDetail = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesCheckList(state, action) {
      state.dataPagesCheckList = action.payload;
      state.dataPagesCheckDetail = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesCheckDetail(state, action) {
      state.dataPagesCheckDetail = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllCategoryGroupList(state, action) {
      state.dataCategoryGroup = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllSyncList(state, action) {
      state.dataSyncList = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllKategorije(state, action) {
      state.dataCategoryKategorije = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllNadgrupe(state, action) {
      state.dataCategoryNadgrupe = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchClearNadgrupeList(state, action) {
      state.dataCategoryNadgrupe = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllGrupe(state, action) {
      state.dataCategoryGrupe = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchClearGrupeList(state, action) {
      state.dataCategoryGrupe = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllProductCategoriesList(state, action) {
      state.error = undefined;
      state.loading = false;
    },
  },
});

export const productActions = productSlice.actions;

export default productSlice.reducer;
